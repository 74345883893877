import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

class IndicationSafetyWarnings extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		return (
			<div className="page">
				<div className="sectionBreadcrumb">
					<Link to="/">
						<i className="las la-angle-left" /> BACK
					</Link>
				</div>
				<Container fluid="xl" className="withBreadcrumb">
					<Row style={{ height: '10vh' }}>
						<Col xs={12}>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<div
									style={{
										border: '1px solid #63666A',
										minWidth: '8vh',
										width: '8vh',
										minHeight: '8vh',
										height: '8vh',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										marginRight: 10
									}}
								>
									<i
										className="las la-exclamation-circle"
										style={{ fontSize: '5vh', color: '#63666A' }}
									/>
								</div>
								<h5 style={{ color: 'black', margin: 0, fontFamily: 'BrandonGrotesqueBold' }}>
									BRIEF SUMMARY
								</h5>
							</div>
						</Col>
					</Row>

					<p style={{ fontSize: '1.5vh', color: '#474C55' }}>
						<br />
						<span className="bold" style={{ fontSize: '1.5vh' }}>
							<strong>SAFETY INFORMATION</strong>
						</span>
						<br />
						*Key episodes is a feature of Merlin.net<sup>TM</sup>
						<br />
						<br />
						<span className="bold" style={{ fontSize: '1.5vh' }}>
							<strong>ABBOTT</strong>
						</span>
						<br />
						15900 Valley View Court Sylmar, CA 91342 Tel: +1 818 362 6822 Abbott.com
						<br />
						<br />
						<span className="bold" style={{ fontSize: '1.5vh' }}>
							<strong>Rx ONLY</strong>
						</span>
						<br />
						<br />
						<span className="bold" style={{ fontSize: '1.5vh' }}>
							<strong>INDICATIONS:</strong>
						</span>
						<br />
						The Jot Dx<sup>TM</sup> is indicated for the monitoring and diagnostic evaluation of patients
						who experience unexplained symptoms such as: dizziness, palpitations, chest pain, syncope, and
						shortness of breath, as well as patients who are at risk for cardiac arrhythmias. It is also
						indicated for patients who have been previously diagnosed with atrial fibrillation or who are
						susceptible to developing atrial fibrillation. The Jot Dx<sup>TM</sup> ICM has not been
						specifically tested for pediatric use.
						<br />
						<br />
						<span className="bold" style={{ fontSize: '1.5vh' }}>
							<strong>INTENDED USE:</strong>
						</span>
						<br />
						The Jot Dx<sup>TM</sup> ICM is intended to help physicians monitor, diagnose and document the
						rhythm in patients who are susceptible to cardiac arrhythmias and unexplained symptoms, as
						indicated.
						<br />
						<br />
						<span className="bold" style={{ fontSize: '1.5vh' }}>
							<strong>CONTRAINDICATIONS:</strong>
						</span>
						<br />
						There are no known contraindications for the insertion of the Jot D<sup>TM</sup> ICM. However,
						the patient’s particular medical condition may dictate whether or not a subcutaneous,
						chronically inserted device can be tolerated.
						<br />
						<br />
						<span className="bold" style={{ fontSize: '1.5vh' }}>
							<strong>ADVERSE EVENTS:</strong>
						</span>
						<br />
						Possible adverse events (in alphabetical order) associated with the device, include the
						following: Allergic reaction, Bleeding, Chronic nerve damage, Erosion, Excessive fibrotic tissue
						growth, Extrusion, Formation of hematomas or cysts, Infection, Keloid formation and Migration.
						Refer to the User’s Manual for detailed indications, contraindications, warnings, precautions
						and potential adverse events.
						<br />
						<br />
						An Abbott mobile transmitter is available for patients without their own compatible mobile
						device.
						<br />
						<br />
						<sup>TM</sup> Indicates a trademark of the Abbott group of companies.
						<br />‡ Indicates a third-party trademark, which is property of its respective owner.
						<br />Bluetooth and the Bluetooth logo are registered trademarks of Bluetooth SIG, Inc.
						<br />&copy; 2022 Abbott. All Rights Reserved.
						<br />MAT-2205925 v1.0 | Item is approved for US Internal use only
					</p>
					<br />
					<br />
				</Container>
			</div>
		);
	}
}

export default IndicationSafetyWarnings;
