import React, { Component } from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const languages = {
	english: 'English',
	french: 'French',
	german: 'German',
	italian: 'Italian',
	portuguese: 'Portuguese',
	spanish: 'Spanish'
};

class LanguageChooser extends Component {
	constructor(props) {
		super(props);
		const languageChosen = localStorage.getItem('language');
		this.state = {
			language: languageChosen,
			dropdownOpen: false
		};
	}

	chooseLanguage = (language) => {
		localStorage.setItem('language', language);
		this.setState({ language: language }, () => {
			if (this.props.update) {
				this.props.update();
			}
		});
	};

	toggle = () => {
		this.setState({ dropdownOpen: !this.state.dropdownOpen });
	};

	render() {
		const { language, dropdownOpen } = this.state;
		return (
			<ButtonDropdown isOpen={dropdownOpen} toggle={this.toggle} className="languageChooser">
				<DropdownToggle
					style={{
						textAlign: 'left',
						background: 'white',
						color: 'gray',
						display: 'flex',
						alignItems: 'center',
						borderColor: 'lightgray',
						fontFamily: 'BrandonGrotesqueRegular',
						borderRadius: 2,
						marginTop: 10,
						marginBottom: 10
					}}
				>
					<span style={{ color: '#212529', marginRight: 5 }}>Language: </span>
					{'  '}
					{languages[language]}{' '}
					<i style={{ marginLeft: 'auto', color: 'lightgray' }} className="las la-angle-down" />
				</DropdownToggle>
				<DropdownMenu style={{ width: '100%' }}>
					{Object.keys(languages).map((lk) => (
						<DropdownItem
							onClick={() => this.chooseLanguage(lk)}
							style={{ fontFamily: 'BrandonGrotesqueRegular' }}
						>
							{languages[lk]}
						</DropdownItem>
					))}
				</DropdownMenu>
			</ButtonDropdown>
		);
	}
}

export default LanguageChooser;
