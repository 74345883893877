import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';

import BR2250297970JotDxHCPBrochureGLEXTMAT2206433v1WRFR from '../../assets/pdf-thumbnails/BR.2250297970.JotDx.HCP.Brochure.GL.EXT.MAT-2206433v1.WR_FR_thumbnail.png';
import SS2664828161ICMIncisionToolGLEXTWRFR from '../../assets/pdf-thumbnails/SS.2664828161.ICM.IncisionTool.GL.EXT.WR_FR_thumbnail.png';
import BR2250297970JotDxHCPBrochureGLEXTMAT2206436v1_ES from '../../assets/pdf-thumbnails/BR.2250297970.JotDx.HCP.Brochure.GL.EXT.MAT-2206436v1_ES_thumbnail.png';
import BR2250297970JotDxHCPBrochureGLEXTMAT2206434v1_IT from '../../assets/pdf-thumbnails/BR.2250297970.JotDx.HCP.Brochure.GL.EXT.MAT-2206434v1_IT_thumbnail.png';
import BR2250297970JotDxHCPBrochureGLEXTMAT2206590v1WR_DE from '../../assets/pdf-thumbnails/BR.2250297970.JotDx.HCP.Brochure.GL.EXT.MAT-2206590v1.WR_DE_thumbnail.png';
import SS2664828161ICMIncisionToolGLEXTWR_DE from '../../assets/pdf-thumbnails/SS.2664828161.ICM.IncisionTool.GL.EXT.WR_DE_thumbnail.png';

import PLACEHOLDER_IMG from '../../assets/Placeholders/placeholder-thumbnail.png';
import LanguageChooser from '../../Components/Inputs/LanguageChooser';

import jotDxICMBrochure from '../../assets/pdf-thumbnails/51320 Jot Dx ICM HCP Brochure_preview.png';
import newBladeBrochure from '../../assets/pdf-thumbnails/MAT-2011067_v1.0_ConfirmRx_Blade_SS_preview.png';
import regulatorySupportLetter from '../../assets/pdf-thumbnails/JotDx_RegulatoryReleaseLetter_preview.png';
import trainingDeckPatientApp from '../../assets/pdf-thumbnails/53102 Training Deck for myMerlin App for Jot Dx launch_preview.png';
import trainingDeckMyMerlinNet from '../../assets/pdf-thumbnails/53100 Training Deck for myMerlin.net for Jot Dx launch_preview.png';
import repToPhysician from '../../assets/pdf-thumbnails/Jot-Dx-Product-Presentation preview.png';
import MRILetter from '../../assets/pdf-thumbnails/52967-Jot-Dx-ICM-3T-customer-letter preview.png';
import MRIFAQGuide from '../../assets/pdf-thumbnails/52971-Jot-Dx-ICM-3T-MRI-Ready-FAQ preview.png';
import ClearanceLetter from '../../assets/pdf-thumbnails/K202888-510k Clearance letter preview.png';
import JotDxCatalog from '../../assets/pdf-thumbnails/53805-Jot-Dx-ICM-Catalog-Page preview.png';
import myMERLIN_MOBILE_APP_SUPPORT_FOR_iOS from '../../assets/pdf-thumbnails/ABT.CRM.MyMerlin.Mobile.App.Support.IOS_r3.1-preview.png';
import myMERLIN_MOBILE_APP_SUPPORT_FOR_ANDROID from '../../assets/pdf-thumbnails/Guide_53547_Android-Connectivity-ICM-Support-Guide_MAT2000524v2.0-preview.png';
import myMERLIN_MOBILE_APP_SUPPORT_FOR_MTX from '../../assets/pdf-thumbnails/Guide_53546_MTX-Connectivity-ICM-Support-Guide_MAT2000063v3.0-preview.png';
import myMERLIN_MOBILE_APP_PATIENT_GUIDE from '../../assets/pdf-thumbnails/Guide_53676_Patient-QS-Guide-for-ICM-and-myMerlin_MAT2110158v1.0-preview.png';

const resources = {
	english: [
		{
			title: [ 'JOT Dx ICM BROCHURE' ],
			thumbnail: jotDxICMBrochure,
			fileName: '53657-Jot-Dx-physician-brochure.pdf',
			type: 'pdf'
		},
		{
			title: [ 'NEW BLADE BROCHURE' ],
			thumbnail: newBladeBrochure,
			fileName: 'MAT-2011067-v1.0-ConfirmRx-Blade-SS.pdf',
			type: 'pdf'
		},
		{
			title: [ 'REGULATORY SUPPORT LETTER' ],
			thumbnail: regulatorySupportLetter,
			fileName: 'JotDx-RegulatoryReleaseLetter.pdf',
			type: 'pdf'
		},
		{
			title: [ 'TRAINING DECK FOR myMerlin PATIENT APP' ],
			thumbnail: trainingDeckPatientApp,
			fileName: '53102-Training-Deck-for-myMerlin-App-for-Jot-Dx-launch.pdf',
			type: 'pdf'
		},
		{
			title: [ 'TRAINING DECK FOR myMerlin.net' ],
			thumbnail: trainingDeckMyMerlinNet,
			fileName: '53100-Training-Deck-for-myMerlin.net-for-Jot-Dx-launch.pdf',
			type: 'pdf'
		},
		{
			title: [ 'REP TO PHYSICIAN PRESENTATION' ],
			thumbnail: repToPhysician,
			fileName: '53670-Jot-Dx-ICM-Product-Presentation.pdf',
			type: 'pdf'
		},
		{
			title: [ '3T MRI LETTER' ],
			thumbnail: MRILetter,
			fileName: '52967-Jot-Dx-ICM-3T-customer-letter.pdf',
			type: 'pdf'
		},
		{
			title: [ '3T MRI FAQ GUIDE' ],
			thumbnail: MRIFAQGuide,
			fileName: '53662-3T-MRI-FAQ.pdf',
			type: 'pdf'
		},
		{
			title: [ '510K CLEARANCE LETTER' ],
			thumbnail: ClearanceLetter,
			fileName: 'K212206-Jot-Dx-510k-Clearance-letter.pdf',
			type: 'pdf'
		},
		{
			title: [ 'JOT Dx ICM CATALOG PAGE' ],
			thumbnail: JotDxCatalog,
			fileName: '53805-Jot-Dx-ICM-Catalog-Page.pdf',
			type: 'pdf'
		},

		{
			title: [ 'myMERLIN MOBILE APP SUPPORT FOR iOS' ],
			thumbnail: myMERLIN_MOBILE_APP_SUPPORT_FOR_iOS,
			fileName: 'ABT_CRM_MyMerlin_Mobile_App_Support_IOS_r3.1.pdf',
			type: 'pdf'
		},
		{
			title: [ 'myMERLIN MOBILE APP SUPPORT FOR ANDROID' ],
			thumbnail: myMERLIN_MOBILE_APP_SUPPORT_FOR_ANDROID,
			fileName: 'Guide_53547_Android_Connectivity_ICM_Support_Guide_MAT2000524v2.0.pdf',
			type: 'pdf'
		},
		{
			title: [ 'myMERLIN MOBILE APP SUPPORT FOR MTX' ],
			thumbnail: myMERLIN_MOBILE_APP_SUPPORT_FOR_MTX,
			fileName: 'Guide_53546_MTX_Connectivity_ICM_Support_Guide_MAT2000063v3.0.pdf',
			type: 'pdf'
		},
		{
			title: [ 'myMERLIN MOBILE APP PATIENT GUIDE' ],
			thumbnail: myMERLIN_MOBILE_APP_PATIENT_GUIDE,
			fileName: 'Guide_53676_Patient-QS_Guide_for_ICM_and_myMerlin_MAT2110158v1.0.pdf',
			type: 'pdf'
		}
	],

	french: [
		{
			title: [ 'JOT Dx ICM BROCHURE' ],
			thumbnail: BR2250297970JotDxHCPBrochureGLEXTMAT2206433v1WRFR,
			fileName: 'BR.2250297970.JotDx.HCP.Brochure.GL.EXT.MAT-2206433v1.WR_FR_.pdf',
			type: 'pdf'
		},
		{
			title: [ 'NEW BLADE BROCHURE' ],
			thumbnail: SS2664828161ICMIncisionToolGLEXTWRFR,
			fileName: 'SS.2664828161.ICM.IncisionTool.GL.EXT.WR_FR.pdf',
			type: 'pdf'
		}
	],
	spanish: [
		{
			title: [ 'JOT Dx ICM BROCHURE' ],
			thumbnail: BR2250297970JotDxHCPBrochureGLEXTMAT2206436v1_ES,
			fileName: 'BR.2250297970.JotDx.HCP.Brochure.GL.EXT.MAT-2206436v1_ES_.pdf',
			type: 'pdf'
		}
	],
	italian: [
		{
			title: [ 'JOT Dx ICM BROCHURE' ],
			thumbnail: BR2250297970JotDxHCPBrochureGLEXTMAT2206434v1_IT,
			fileName: 'BR.2250297970.JotDx.HCP.Brochure.GL.EXT.MAT-2206434v1_IT_.pdf',
			type: 'pdf'
		}
	],
	portuguese: [],

	german: [
		{
			title: [ 'JOT Dx ICM BROCHURE' ],
			thumbnail: BR2250297970JotDxHCPBrochureGLEXTMAT2206590v1WR_DE,
			fileName: 'BR.2250297970.JotDx.HCP.Brochure.GL.EXT.MAT-2206590v1.WR_DE_.pdf',
			type: 'pdf'
		},
		{
			title: [ 'NEW BLADE BROCHURE' ],
			thumbnail: SS2664828161ICMIncisionToolGLEXTWR_DE,
			fileName: 'SS.2664828161.ICM.IncisionTool.GL.EXT.WR_DE.pdf',
			type: 'pdf'
		}
	]
};
class AHPResources extends Component {
	state = {
		searchText: '',
		sortingAsc: true,
		resources: resources[localStorage.getItem('language')] || resources['english'],
		displayAsList: true
	};

	searchResources = (e) => {
		const searchText = e.target.value;
		this.setState({ searchText: searchText });
	};

	updateResources = (e) => {
		this.setState({
			resources: resources[localStorage.getItem('language')] || resources['english']
		});
	};

	sortResources = () => {
		const sortingAsc = !this.state.sortingAsc;
		const resources = this.state.resources.sort(
			(a, b) =>
				sortingAsc
					? a.title < b.title ? -1 : a.title > b.title ? 1 : 0
					: b.title < a.title ? -1 : b.title > a.title ? 1 : 0
		);
		this.setState({ sortingAsc: sortingAsc, resources: resources });
	};

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		const { resources, searchText, sortingAsc, displayAsList } = this.state;
		const filteredResources = resources.filter((rsrc) =>
			rsrc.title.toString().toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
		);
		return (
			<div className="page">
				<div className="sectionBreadcrumb">
					<Link to="/">
						<i className="las la-angle-left" /> BACK{' '}
					</Link>{' '}
				</div>
				<Container fluid="xl" className="withBreadcrumb">
					<Row className="dark-blue banner">
						<Col xs={6}>
							<div>
								<h4 style={{ color: '#fff', letterSpacing: 2 }}>
									HCP <br /> RESOURCES{' '}
								</h4>{' '}
							</div>{' '}
						</Col>{' '}
						{/* <Col xs={6}>
                							<img src={}/>
                						</Col> */}{' '}
					</Row>{' '}
					<br />
					<Row style={{ boxShadow: '#ececec 0 5px 5px' }}>
						<Col xs={12}>
							<LanguageChooser update={this.updateResources} />
							<InputGroup>
								<InputGroupAddon addonType="prepend">
									<InputGroupText style={{ background: 'white', borderRight: 0 }}>
										<i className="las la-search" style={{ color: '#009CDE' }} />{' '}
									</InputGroupText>{' '}
								</InputGroupAddon>{' '}
								<Input
									placeholder="Search HCP Resources..."
									value={searchText}
									onChange={this.searchResources}
									style={{ borderLeft: 0, paddingLeft: 0, WebkitTapHighlightColor: 0 }}
								/>{' '}
							</InputGroup>{' '}
							<div style={{ display: 'flex', alignItems: 'center', marginTop: 15, marginBottom: 15 }}>
								<div style={{ fontSize: '1.5vh' }}>
									SORT BY: {' '}
									<span onClick={this.sortResources} style={{ color: '#009CDE' }}>
										{' '}
										{sortingAsc ? 'A - Z' : 'Z - A'}{' '}
									</span>{' '}
								</div>{' '}
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										marginLeft: 'auto',
										color: '#BBBBBB'
									}}
								>
									<div
										style={{
											border: '1px solid #BBBBBB',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											textAlign: 'center',
											borderRadius: '50%',
											padding: 5,
											marginRight: 15,
											background: displayAsList ? '#009CDE' : 'white',
											color: displayAsList ? 'white' : ''
										}}
										onClick={() => this.setState({ displayAsList: true })}
									>
										<i className="las la-th-list" />
									</div>
									<div
										style={{
											border: '1px solid #BBBBBB',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											textAlign: 'center',
											borderRadius: '50%',
											padding: 5,
											background: !displayAsList ? '#009CDE' : 'white',
											color: !displayAsList ? 'white' : ''
										}}
										onClick={() => this.setState({ displayAsList: false })}
									>
										<i className="las la-th-large" />
									</div>{' '}
								</div>{' '}
							</div>{' '}
						</Col>{' '}
					</Row>{' '}
					<br />
					<Row>
						<Col xs={12} />{' '}
					</Row>{' '}
					<Row>
						{' '}
						{displayAsList ? (
							filteredResources.map((resource) => (
								<Col xs={12}>
									<Link
										to={`/resource-libraries/pdf-viewer?title=${resource.title
											.map((t) => (t instanceof Object ? 'TM' : t))
											.join('')}&file=${resource.fileName}`}
									>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												width: '100%',
												borderBottom: '1px solid #BBBBBB',
												paddingTop: 10,
												paddingBottom: 10
											}}
										>
											<img
												src={resource.thumbnail}
												style={{
													height: '5vh',
													marginRight: 10
												}}
											/>{' '}
											<div
												style={{
													fontSize: '1.5vh',
													lineHeight: '1.5vh',
													color: '#63666A',
													fontFamily: 'BrandonGrotesqueBold'
												}}
											>
												{resource.title}{' '}
											</div>
											<i
												className="las la-angle-right"
												style={{ marginLeft: 'auto', color: 'gray' }}
											/>{' '}
										</div>{' '}
									</Link>{' '}
								</Col>
							))
						) : (
							filteredResources.map((resource) => (
								<Col xs={6} lg={3}>
									<Link
										to={`/resource-libraries/pdf-viewer?title=${resource.title}&file=${resource.fileName}`}
									>
										<img
											src={resource.thumbnail}
											style={{
												maxWidth: '100%',
												maxHeight: '20vh',
												border: '1px solid #dcdcdb',
												maxWidth: '100%'
											}}
										/>{' '}
										<div
											style={{
												fontSize: '1.2vh',
												lineHeight: '1.5vh',
												marginTop: 5,
												marginBottom: 15,
												color: '#63666A',
												fontFamily: 'BrandonGrotesqueBold'
											}}
										>
											{resource.title}{' '}
										</div>{' '}
									</Link>{' '}
								</Col>
							))
						)}{' '}
						{filteredResources.length <= 0 && (
							<Col xs={12}>
								<p style={{ textAlign: 'center' }}> No results for "{searchText}" </p>{' '}
							</Col>
						)}{' '}
					</Row>{' '}
					<br />
					<br />
					<br />
					<br />
					<br />
					<p style={{ fontSize: '1.5vh' }}>
						© 2022 Abbott. All Rights Reserved.<br />
						MAT-2205929 v1.0 | Item is approved for US Internal use only
					</p>
				</Container>{' '}
			</div>
		);
	}
}

export default AHPResources;
